.site-logo {
  color: red;
  font-weight: bold;
  font-size: 120%;
  margin-right: 1em;
}

a,
a:visited {
  text-decoration: none;
  color: black;
}

.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.user-info {
  padding: 1rem;
}

.welcome-widget-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.widget {
  display: flex;
  justify-content: flex-end;
}

.widget-box-container {
  display: flex;
  flex-direction: column;

  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.widget-box-itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  border: 5px solid #eee;
}

.widget-box-item {
  display: flex;
  flex-direction: column;

  align-items: center;
  border-left: 3px solid #eee;
  padding: 5px;
  text-align: center;
}

.widget-box-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.table-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
}

.table-container-print {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.table-print {
  max-width: 21cm;
  margin-bottom: 2rem;
}

.table-cell-print {
  vertical-align: top;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.switch-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.user-welcome-box {
  width: auto;
  margin-top: 2rem;
  margin-left: 1rem;
}

.days-remaining {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  box-shadow: 24;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media only screen and (min-width: 900px) {
  .switch-button {
    display: none;
  }
}

@media only screen and (max-width: 587px) {
  .welcome-widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .user-welcome-box {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .widget {
    display: block;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .widget-box-container {
    display: flex;
    flex-direction: column;
    margin: 0rem;
  }

  .widget-box-itemContainer {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

  .widget-box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    text-align: center;
    border: none;
  }

  .widget-box-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
  }

  .table-container {
    display: block;
    justify-content: flex-end;
    margin-bottom: 4rem;
  }

  .table-container-print {
    display: block;
    justify-content: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .table-print {
    min-width: max-content;
    margin-bottom: 2rem;
  }
  .body {
    overflow-y: scroll;
  }
  .switch-button {
    display: flex;
    justify-content: center;
  }

  .days-remaining {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }
}
